import React, { Component } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import moment from "moment";
import Select from "react-select";

class EditForm extends Component {
  constructor(props) {
    super(props);
    var retrievedObject = getLocalStorageItem("myStylistAdmin");
    this.state = {
      token: retrievedObject ? retrievedObject.token : "",
      selectedStates: [],
      selectedDistricts: [],
      selectedCities: [],
      selectedServices: [],
      selectedSubServices: [],
      selectedExperts: [],
      states: [],
      districts: [],
      cities: [],
      services: [],
      subServices: [],
      experts: [],
      isLoading: false,
      error: null,
      status: "",
      offer_name: "",
      number_of_offers: "",
      startDate: "",
      endDate: "",
      additional_information: "",
      errors: {},
      featured_image: "",
      selectedFile: null,
      previewURL: null,
    };
  }

  componentDidMount() {
    const id = window.location.pathname.split("/").pop();
    this.getAllUsersResults();
    this.getOfferDetails(id);
  }

  getAllUsersResults = async () => {
    try {
      const { token } = this.state;
      const {
        AdminStateList,
        AdminDistrictList,
        AdminCityList,
        AdminServicesListMobile,
        AdminExpertList,
      } = adminendPoints;

      const method = "GET";
      const contentType = "application/json";
      const urls = {
        stateUrl: `${AdminStateList}`,
        districtUrl: `${AdminDistrictList}`,
        cityUrl: `${AdminCityList}`,
        serviceUrl: `${AdminServicesListMobile}`,
        expertUrl: `${AdminExpertList}`,
      };

      const fetchAllData = async () => {
        const data = {};
        await Promise.all(
          Object.keys(urls).map(async (key) => {
            const response = await APICaller(
              urls[key],
              method,
              "",
              contentType,
              token
            );
            data[key] = response.data;
          })
        );
        return data;
      };

      const { stateUrl, districtUrl, cityUrl, serviceUrl, expertUrl } =
        await fetchAllData();

      this.setState({
        states: stateUrl.states,
        districts: districtUrl.districts,
        cities: cityUrl.citys,
        services: serviceUrl.services,
        experts: expertUrl.expertusers,
      });
    } catch (error) {
      console.log("Error fetching data: ", error);
      this.setState({ errorMsg: "Error fetching data" });
    }
  };

  getOfferDetails = async (id) => {
    try {
      const { token, services } = this.state;
      const { AdminOfferDetails } = adminendPoints;
      const offerUrl = `${AdminOfferDetails}/${id}`;

      const response = await APICaller(
        offerUrl,
        "GET",
        "",
        "application/json",
        token
      );
      const offerDetails = response.data.offer;

      if (services.length === 0) {
        await this.getAllUsersResults();
      }

      const updatedServices = this.state.services;
      const updatedExperts = this.state.experts;
      const selectedStates = offerDetails.state.map((state) => ({
        value: state.state_id,
        label: state.state_name,
      }));

      const selectedDistricts = offerDetails.district.map((district) => ({
        value: district.district_id,
        label: district.district_name,
      }));

      const selectedCities = offerDetails.city.map((city) => ({
        value: city.city_id,
        label: city.city_name,
      }));

      const selectedService = updatedServices.find(
        (service) => service._id === offerDetails.service.service_id
      );

      const selectedServices = {
        value: offerDetails.service.service_id,
        label: selectedService
          ? selectedService.service_name
          : "Unknown Service",
      };

      const selectedSubServices = {
        value: offerDetails.sub_services.sub_service_id._id,
        label: offerDetails.sub_services.sub_service_id.sub_service_name,
      };

      const selectedExperts = offerDetails.expert_id
        ? [
            {
              value: offerDetails.expert_id,
              label: offerDetails.expertDetails.name,
            },
          ]
        : [];

      this.setState({
        selectedStates,
        selectedDistricts,
        selectedCities,
        selectedServices,
        selectedSubServices,
        selectedExperts,
        offerName: offerDetails.offer_name || "",
        numberOfOffers: offerDetails.number_of_offers || "",
        startDate: offerDetails.start_date
          ? new Date(offerDetails.start_date)
          : "",
        endDate: offerDetails.end_date ? new Date(offerDetails.end_date) : "",
        additionalInformation: offerDetails.additional_information || "",
        number_of_offers: offerDetails.number_of_offers || "",
        discount: offerDetails.discount || "",
      });
    } catch (error) {
      console.log("Error fetching offer details: ", error);
      this.setState({ errorMsg: "Error fetching offer details" });
    }
  };

  handleServiceChange = async (selectedOptions) => {
    this.setState({ selectedServices: selectedOptions });

    const selectedServiceIds = selectedOptions.map((service) => service.value);

    const subServices = await this.fetchSubServices(selectedServiceIds);

    this.setState({
      subServices,
      selectedSubServices: [],
    });
  };

  handleStateChange = (selectedOptions) => {
    this.setState({
      selectedStates: selectedOptions,
      selectedDistricts: [],
      selectedCities: [],
    });
  };

  handleDistrictChange = (selectedOptions) => {
    this.setState({ selectedDistricts: selectedOptions, selectedCities: [] });
  };

  handleCityChange = (selectedOptions) => {
    this.setState({ selectedCities: selectedOptions });
  };

  handleExpertChange = (selectedOptions) => {
    this.setState({ selectedExperts: selectedOptions });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleFileSelect = (e) => {
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.onloadend = () => {
      this.setState({
        selectedFile: file,
        previewURL: reader.result,
        fileName: file.name,
      });
    };
    reader.readAsDataURL(file);
  };

  handleFileRemove = () => {
    document.getElementById("fileName").value = "";

    this.setState({
      selectedFile: null,
      previewURL: null,
      fileName: "",
    });
  };

  render() {
    const {
      selectedStates,
      selectedDistricts,
      selectedCities,
      states,
      districts,
      cities,
      token,
      services,
      selectedServices,
      subServices,
      selectedExperts,
      experts,
      offerName,
      number_of_offers,
      startDate,
      endDate,
      additionalInformation,
      errors,
      discount,
      previewURL,
    } = this.state;

    if (token === "") {
      return <Navigate to="/" />;
    }

    const serviceOptions = services.map((service) => ({
      value: service._id,
      label: service.service_name,
    }));

    const subServiceOptions = subServices.map((subService) => ({
      value: subService.value,
      label: subService.label,
    }));

    const expertOptions = experts.map((expert) => ({
      value: expert._id,
      label: expert.name,
    }));

    return (
      <div className="main d-flex">
        <SideBar />
        <div className="main-right-content flex-fill">
          <Header />
          <div className="card mb-4">
            <div className="card-body">
              <div className="d-flex align-items-center mb-4">
                <div className="bs-r-6 ps-3">
                  <h4 className="card-title mb-0">Edit Offer Information</h4>
                  <p className="mb-0 tc-5">1349 User Added</p>
                </div>
                <div className="ms-auto">
                  <Link to="/ManageOffers" className="link">
                    <img
                      className="me-2"
                      src="assets/img/icon-edit1.svg"
                      alt=""
                      title=""
                    />
                    <span>Back</span>
                  </Link>
                </div>
              </div>
              <form className="form-area1 row g-4" onSubmit={this.handleSubmit}>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="status">Select Service</label>
                    <Select
                      id="service"
                      options={serviceOptions}
                      value={selectedServices}
                      isMulti
                      onChange={this.handleServiceChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <label htmlFor="service">Sub Service</label>
                  <Select
                    id="subServices"
                    options={subServiceOptions}
                    isMulti
                    value={this.state.selectedSubServices}
                    onChange={(selectedOptions) =>
                      this.setState({ selectedSubServices: selectedOptions })
                    }
                  />
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="status">Select State</label>
                    <Select
                      value={selectedStates}
                      onChange={this.handleStateChange}
                      options={states.map((state) => ({
                        value: state._id,
                        label: state.state_name,
                      }))}
                      isMulti
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="status">Select District</label>
                    <Select
                      value={selectedDistricts}
                      onChange={this.handleDistrictChange}
                      options={districts.map((district) => ({
                        value: district._id,
                        label: district.district_name,
                      }))}
                      isMulti
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="status">Select City</label>
                    <Select
                      value={selectedCities}
                      onChange={this.handleCityChange}
                      options={cities.map((city) => ({
                        value: city._id,
                        label: city.city_name,
                      }))}
                      isMulti
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="status">Select Expert</label>
                    <Select
                      value={selectedExperts}
                      onChange={this.handleExpertChange}
                      options={expertOptions}
                      isMulti
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <label htmlFor="offer_name">Offer Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="offer_name"
                    placeholder="Offer Name"
                    name="offer_name"
                    onChange={this.handleInputChange}
                    value={offerName}
                  />
                  {errors.offer_name && (
                    <p className="error">{errors.offer_name}</p>
                  )}
                </div>

                <div className="col-lg-6">
                  <label htmlFor="startDate">Start Date</label>
                  <input
                    type="date"
                    className="form-control"
                    id="startDate"
                    placeholder="Start Date"
                    name="startDate"
                    value={startDate}
                    onChange={this.handleInputChange}
                  />
                  {errors.startDate && (
                    <p className="error">{errors.startDate}</p>
                  )}
                </div>

                <div className="col-lg-6">
                  <label htmlFor="startDate">End Date</label>
                  <input
                    type="date"
                    className="form-control"
                    id="endDate"
                    placeholder="End Date"
                    name="endDate"
                    value={endDate}
                    onChange={this.handleInputChange}
                  />
                  {errors.endDate && <p className="error">{errors.endDate}</p>}
                </div>

                <div className="col-lg-6">
                  <label htmlFor="number_of_offers"> Number of Offers </label>
                  <input
                    type="text"
                    className="form-control"
                    id="number_of_offers"
                    placeholder="Number of Offers"
                    name="number_of_offers"
                    value={number_of_offers}
                    onChange={this.handleInputChange}
                  />
                  {errors.number_of_offers && (
                    <p className="error">{errors.number_of_offers}</p>
                  )}
                </div>

                <div className="col-lg-6">
                  <label htmlFor="discount">Discount</label>
                  <input
                    type="text"
                    className="form-control"
                    id="discount"
                    placeholder="Discount"
                    name="discount"
                    value={discount}
                    onChange={this.handleInputChange}
                  />
                  {errors.discount && (
                    <p className="error">{errors.discount}</p>
                  )}
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="additional_information">
                      Additional Information
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="additional_information"
                      placeholder="Additional Information"
                      name="additional_information"
                      value={additionalInformation}
                      onChange={this.handleInputChange}
                    />
                    {errors.additional_information && (
                      <p className="error">{errors.additional_information}</p>
                    )}
                  </div>
                </div>

                <div className="col">
                  <div className="upload-img-box">
                    <i className="fa-solid fa-file-arrow-up"></i>
                    <p className="mb-0">Upload Image</p>
                    <input
                      type="file"
                      className="form-control"
                      id="fileName"
                      placeholder="Absence through"
                      name="fileName"
                      accept="image/*"
                      onChange={this.handleFileSelect}
                    />
                    {previewURL && (
                      <div>
                        <img
                          src={previewURL}
                          alt="Category Preview"
                          style={{ width: "100px", height: "100px" }}
                        />
                        <button type="button" onClick={this.handleFileRemove}>
                          Remove
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-12">
                  <button type="submit" className="btn-blue1">
                    Update Changes
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default EditForm;
