import React, { Component } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import moment from "moment";
import Select from "react-select";

class updateLocation extends Component {
  constructor(props) {
    super(props);
    var retrievedObject = getLocalStorageItem("myStylistAdmin");
    this.state = {
      token: retrievedObject ? retrievedObject.token : "",
      selectedState: null,
      selectedDistrict: null,
      selectedCity: null,
      states: [],
      districts: [],
      cities: [],
      reviewsResults: [],
      predefinedState: null,
      predefinedDistrict: null,
      predefinedCity: null,
      username: "",
      password: "",
      address: "",
      pincode: "",
      latitude: "",
      longitude: "",
      experience: "",
      gender: "",
      fullName: "",
      email: "",
      homeVisit: "",
      mobileNumber: "",
      freelancer: "",
      salon_name: "",
      user_information: [],
      _id: "",
      successMsg: "",
      errorMsg: "",
      errors: {},
      redirect: false,
      loading: false,
      errors: {},
    };
  }

  componentDidMount() {
    const userid = window.location.pathname.split("/").pop();
    this.fetchUserData(userid);
    this.fetchStates();
  }

  fetchUserData = async (userid) => {
    this.setState({ loading: true });
    const { token } = this.state;
    const { AdminExpertUserDetails } = adminendPoints;
    const method = "POST";
    const contentType = "application/json";

    try {
      const body = { token, userid: userid };
      const response = await APICaller(
        AdminExpertUserDetails,
        method,
        body,
        contentType,
        token
      );

      const { status, user } = response.data;

      if (status === 200 && user) {
        const userInformation =
          user.user_information && user.user_information[0]
            ? user.user_information[0]
            : {};

        this.setState({
          loading: false,
          _id: userid,
          username: user.username || "",
          gender: user.gender || "",
          salon_name: user.salon_name || "",
          fullName: user.name || "",
          email: user.email || "",
          mobileNumber: user.phone || "",
          homeVisit: user.homeVisit || false,
          freelancer: user.freelancer || false,
          experience: userInformation.experience || "",
          user_information: [
            {
              aboutMe: userInformation.aboutMe || "",
              experience: userInformation.experience || "",
              course: userInformation.course || "",
              course_name: userInformation.course_name || "",
              facebook_url: userInformation.facebook_url || "",
              instagram_url: userInformation.instagram_url || "",
            },
          ],
          selectedState:
            user.state && user.state[0]
              ? {
                  value: user.state[0].state_id,
                  label: user.state[0].state_name,
                }
              : null,
          selectedDistrict:
            user.district && user.district[0]
              ? {
                  value: user.district[0].district_id,
                  label: user.district[0].district_name,
                }
              : null,
          selectedCity:
            user.city && user.city[0]
              ? {
                  value: user.city[0].city_id,
                  label: user.city[0].city_name,
                }
              : null,
          address:
            user.addresses && user.addresses[0]
              ? user.addresses[0].address.sector
              : "",
          pincode:
            user.addresses && user.addresses[0]
              ? user.addresses[0].address.pinCode
              : "",
          latitude:
            user.addresses &&
            user.addresses[0] &&
            user.addresses[0].address.location
              ? user.addresses[0].address.location.coordinates[0]
              : "",
          longitude:
            user.addresses &&
            user.addresses[0] &&
            user.addresses[0].address.location
              ? user.addresses[0].address.location.coordinates[1]
              : "",
        });
      } else if (status === 200 && !user) {
        // Handle the case when result is empty
        this.setState({
          loading: false,
          errorMsg: "No user data found",
          username: "",
          gender: "",
          salon_name: "",
          fullName: "",
          email: "",
          mobileNumber: "",
          homeVisit: false,
          freelancer: false,
          experience: "",
          user_information: [
            {
              aboutMe: "",
              experience: "",
              course: "",
              course_name: "",
              facebook_url: "",
              instagram_url: "",
            },
          ],
          selectedState: null,
          selectedDistrict: null,
          selectedCity: null,
          address: "",
          pincode: "",
          latitude: "",
          longitude: "",
        });
      } else {
        console.error("Failed to fetch user data");
        this.setState({
          errorMsg: "Failed to fetch user data",
          loading: false,
        });
      }
    } catch (error) {
      console.error("Failed to fetch user data:", error);
      this.setState({ errorMsg: "Failed to fetch user data", loading: false });
    }
  };

  fetchStates = async () => {
    const { token } = this.state;
    const { AdminStateList } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";
    const stateUrl = `${AdminStateList}`;

    const statesResponse = await APICaller(
      stateUrl,
      method,
      "",
      contentType,
      token
    );

    const { states } = statesResponse.data;
    const statesData = states.map((state) => ({
      value: state._id,
      label: state.state_name,
    }));

    this.setState({ states: statesData });
  };

  fetchDistrictsByState = async (stateValue) => {
    const { token } = this.state;
    const { AdmingetDistrictByStateName } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";
    const districtUrl = `${AdmingetDistrictByStateName}/${stateValue}`;

    const districtResponse = await APICaller(
      districtUrl,
      method,
      "",
      contentType,
      token
    );

    const { data, status } = districtResponse.data;

    if (status === 200) {
      const districtsData = data.map((district) => ({
        value: district._id,
        label: district.district_name,
      }));

      const selectedDistricts = districtsData || [];
      this.setState({ districts: selectedDistricts, cities: [] });
    } else {
      console.error("Failed to fetch districts");
    }
  };

  fetchCitiesByDistrict = async (districtValue) => {
    const { token } = this.state;
    const { AdmingetCityBYDistrictName } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";
    const cityUrl = `${AdmingetCityBYDistrictName}/${districtValue}`;

    const cityResponse = await APICaller(
      cityUrl,
      method,
      "",
      contentType,
      token
    );

    const { data, status } = cityResponse.data;

    if (status === 200) {
      const citiesData = data.map((city) => ({
        value: city._id,
        label: city.city_name,
      }));

      const selectedCities = citiesData || [];
      this.setState({ cities: selectedCities });
    } else {
      console.error("Failed to fetch cities");
    }
  };

  handleStateChange = (selectedOption) => {
    if (!selectedOption) {
      this.setState({
        selectedState: null,
        selectedDistrict: "",
        selectedCity: "",
        districts: [],
        cities: [],
      });
      return;
    }

    const selectedState = selectedOption.value;
    const stateLabel = selectedOption.label;

    this.setState(
      {
        selectedState: { value: selectedState, label: stateLabel },
        selectedDistrict: "",
        selectedCity: "",
        districts: [],
        cities: [],
      },
      () => {
        if (selectedState) {
          this.fetchDistrictsByState(selectedState);
        }
      }
    );
  };

  handleDistrictChange = (selectedOption) => {
    if (!selectedOption) {
      this.setState({
        selectedDistrict: null,
        selectedCity: "",
        cities: [],
      });
      return;
    }

    const selectedDistrict = selectedOption.value;
    const districtLabel = selectedOption.label;

    this.setState(
      {
        selectedDistrict: { value: selectedDistrict, label: districtLabel },
        selectedCity: "",
        cities: [],
      },
      () => {
        if (selectedDistrict) {
          this.fetchCitiesByDistrict(selectedDistrict);
        }
      }
    );
  };

  handleCityChange = (selectedOption) => {
    if (!selectedOption) {
      this.setState({ selectedCity: null });
      return;
    }

    const selectedCity = selectedOption.value;
    const cityLabel = selectedOption.label;

    this.setState({ selectedCity: { value: selectedCity, label: cityLabel } });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSuccClose = (e) => {
    this.setState({
      successMsg: "",
    });
  };

  handleErrorClose = (e) => {
    this.setState({
      errorMsg: "",
    });
  };

  validateForm = () => {
    const {
      gender,
      freelancer,
      fullName,
      experience,
      address,
      homeVisit,
      selectedState,
      selectedDistrict,
      selectedCity,
      pincode,
      salon_name,
      username,
      password,
      email,
    } = this.state;

    const errors = {};

    if (!username) errors.username = "Username is required.";
    if (!password) errors.password = "Password is required.";
    if (!email) errors.email = "Email is required.";
    if (!fullName) errors.fullName = "Name is required.";
    if (!salon_name) errors.salon_name = "Salon Name is required.";
    if (!gender) errors.gender = "Gender is required.";
    if (!freelancer) errors.freelancer = "Freelancer is required.";
    if (!experience) errors.experience = "Experience is required.";
    if (!homeVisit) errors.homeVisit = "HomeVisit is required.";
    if (!address) errors.address = "Address is required.";
    if (!selectedState || !selectedState.value)
      errors.selectedState = "State is required.";
    if (!selectedDistrict || !selectedDistrict.value)
      errors.selectedDistrict = "District is required.";
    if (!selectedCity || !selectedCity.value)
      errors.selectedCity = "City is required.";
    if (!pincode) {
      errors.pincode = "Pincode is required.";
    } else if (!/^\d{6}$/.test(pincode)) {
      errors.pincode = "Pincode must be a 6 digit number.";
    }

    this.setState({ errors });

    console.log("Errors: ", errors); // Add this line for debugging
    console.log("Is valid: ", Object.keys(errors).length === 0); // Add this line for debugging

    return Object.keys(errors).length === 0;
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const {
      _id,
      salon_name,
      freelancer,
      address,
      selectedState,
      selectedDistrict,
      selectedCity,
      pincode,
      latitude,
      longitude,
      fullName,
      email,
      homeVisit,
      experience,
      gender,
      user_information,
      username,
      password,
    } = this.state;

    if (!this.validateForm()) {
      return;
    }

    const body = {
      id: _id,
      salon_name: salon_name,
      gender: gender,
      name: fullName,
      email: email,
      username: username,
      password: password,
      homeVisit: homeVisit,
      experience: experience,
      freelancer: freelancer,
      state: {
        value: selectedState.value,
        label: selectedState.label,
      },
      district: {
        value: selectedDistrict.value,
        label: selectedDistrict.label,
      },
      city: {
        value: selectedCity.value,
        label: selectedCity.label,
      },
      addresses: [
        {
          address: {
            addressType: "Home",
            houseNumber: "",
            sector: address,
            pinCode: pincode,
            landmark: "",
            location: {
              type: "Point",
              coordinates: [parseFloat(longitude), parseFloat(latitude)],
            },
          },
          isDefault: false,
        },
      ],

      user_information: [
        {
          aboutMe: user_information[0].aboutMe || "",
          experience: experience || user_information[0].experience,
          course: user_information[0].course || "No",
          course_name: user_information[0].course_name || "",
          facebook_url: user_information[0].facebook_url || "",
          instagram_url: user_information[0].instagram_url || "",
        },
      ],
    };

    const { OnboardingUpdateBussformation } = adminendPoints;
    const method = "POST";
    const contentType = "application/json";

    this.setState({ loading: true });

    try {
      const response = await APICaller(
        OnboardingUpdateBussformation,
        method,
        body,
        contentType
      );

      const { status, data } = response;

      if (data && status === 200) {
        this.setState(
          {
            successMsg: "Location updated successfully.",
            errorMsg: "",
          },
          () => {
            setTimeout(() => {
              this.setState({ successMsg: "", redirect: true, loading: false });
            }, 5000);
          }
        );
      } else {
        this.setState(
          {
            errorMsg: "Failed to update data. Please try again.",
            successMsg: "",
            loading: false, // Set loading to false
          },
          () => {
            setTimeout(() => {
              this.setState({ errorMsg: "" });
            }, 3000);
          }
        );
      }
    } catch (error) {
      this.setState({
        errorMsg: "Error updating data. Please try again.",
        successMsg: "",
        loading: false, // Set loading to false
      });
    }
  };

  render() {
    const {
      states,
      districts,
      cities,
      selectedState,
      selectedDistrict,
      selectedCity,
      successMsg,
      errorMsg,
      redirect,
      errors,
      pincode,
      address,
      loading,
      experience,
      fullName,
      email,
      password,
      username,
      homeVisit,
      freelancer,
      gender,
      token,
      salon_name,
      predefinedState,
      predefinedDistrict,
      predefinedCity,
      _id,
    } = this.state;

    if (redirect) {
      return <Navigate to={`/EditExpert/${_id}`} />;
    }

    const options = Array.from({ length: 30 }, (_, index) => index + 1);

    return (
      <div className="main d-flex">
        <SideBar />
        <div className="main-right-content flex-fill">
          <Header />

          <div className="card mb-4">
            <div className="card-body">
              <div className="d-flex align-items-center mb-4">
                <div className="bs-r-6 ps-3">
                  <h4 className="card-title mb-0">Update Profile</h4>
                </div>
                <div className="ms-auto">
                  <Link to={`/EditExpert/${_id}`} className="btn-light1 ms-2">
                    <span>Back</span>
                  </Link>
                </div>
              </div>
              <form className="form-area1 row g-4" onSubmit={this.handleSubmit}>
                {errorMsg && (
                  <div className="alert alert-danger" role="alert">
                    {errorMsg}
                  </div>
                )}

                {successMsg && (
                  <div className="alert alert-success" role="alert">
                    {successMsg}
                  </div>
                )}

                {loading && (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                )}

                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="status">Stylist Name</label>
                    <input
                      type="text"
                      name="fullName"
                      value={fullName}
                      onChange={this.handleInputChange}
                      className={`form-control ${
                        errors.fullName ? "is-invalid" : ""
                      }`}
                      placeholder="Enter here..."
                    />
                    {errors.fullName && (
                      <div className="invalid-feedback">{errors.fullName}</div>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="status">Email</label>
                    <input
                      type="text"
                      className={`form-control ${
                        errors.email ? "is-invalid" : ""
                      }`}
                      id="email"
                      placeholder="Email"
                      name="email"
                      value={email}
                      onChange={this.handleInputChange}
                    />
                    {errors.email && (
                      <div className="invalid-feedback">{errors.email}</div>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="status">Home Visit</label>
                    <select
                      name="homeVisit"
                      value={homeVisit}
                      onChange={this.handleInputChange}
                      className={`form-control ${
                        errors.homeVisit ? "is-invalid" : ""
                      }`}
                    >
                      <option value="">Please select</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                    {errors.homeVisit && (
                      <div className="invalid-feedback">{errors.homeVisit}</div>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Are you a Freelancer*</label>
                    <select
                      name="freelancer"
                      value={freelancer}
                      onChange={this.handleInputChange}
                      className={`form-control ${
                        errors.freelancer ? "is-invalid" : ""
                      }`}
                    >
                      <option value="">Please select</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                    {errors.freelancer && (
                      <div className="invalid-feedback">
                        {errors.freelancer}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Salon Name*</label>
                    <input
                      type="text"
                      name="salon_name"
                      value={salon_name}
                      onChange={this.handleInputChange}
                      className={`form-control ${
                        errors.salon_name ? "is-invalid" : ""
                      }`}
                      placeholder="Enter here..."
                    />
                    {errors.salon_name && (
                      <div className="invalid-feedback">
                        {errors.salon_name}
                      </div>
                    )}
                  </div>
                </div>
                {freelancer === "No" && (
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Salon Address</label>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.address ? "is-invalid" : ""
                        }`}
                        value={address}
                        name="address"
                        placeholder="Address here..."
                        onChange={this.handleInputChange}
                      />
                      {errors.address && (
                        <div className="text-danger">{errors.address}</div>
                      )}
                    </div>
                  </div>
                )}

                <div className="col-lg-6">
                  <div className="form-group">
                    <label for="" className="form-label">
                      Username
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        errors.username ? "is-invalid" : ""
                      }`}
                      id="username"
                      placeholder="Username"
                      name="username"
                      value={username}
                      onChange={this.handleInputChange}
                    />
                    {errors.username && (
                      <div className="invalid-feedback">{errors.username}</div>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label for="" className="form-label">
                      Password
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        errors.password ? "is-invalid" : ""
                      }`}
                      id="password"
                      placeholder="Password"
                      name="password"
                      onChange={this.handleInputChange}
                    />
                    {errors.password && (
                      <div className="invalid-feedback">{errors.password}</div>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label for="" className="form-label">
                      Gender
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        errors.gender ? "is-invalid" : ""
                      }`}
                      id="gender"
                      placeholder="gender"
                      name="gender"
                      value={gender}
                      onChange={this.handleInputChange}
                    />
                    {errors.gender && (
                      <div className="invalid-feedback">{errors.gender}</div>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label for="" className="form-label">
                      Experience
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        errors.experience ? "is-invalid" : ""
                      }`}
                      id="experience"
                      placeholder="experience"
                      name="experience"
                      value={experience}
                      onChange={this.handleInputChange}
                    />
                    {errors.experience && (
                      <div className="invalid-feedback">
                        {errors.experience}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label for="" className="form-label">
                      Pincode:
                    </label>
                    <input
                      type="text"
                      value={pincode}
                      className={`form-control ${
                        errors.pincode ? "is-invalid" : ""
                      }`}
                      name="pincode"
                      placeholder="Enter here"
                      onChange={this.handleInputChange}
                    />
                    {errors.pincode && (
                      <div className="invalid-feedback">{errors.pincode}</div>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label for="" className="form-label">
                      Select State:
                    </label>
                    <Select
                      value={selectedState || predefinedState}
                      options={states}
                      onChange={this.handleStateChange}
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label for="" className="form-label">
                      Select District:
                    </label>
                    <Select
                      value={selectedDistrict || predefinedDistrict}
                      options={districts}
                      onChange={this.handleDistrictChange}
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label for="" className="form-label">
                      Select City:
                    </label>
                    <Select
                      value={selectedCity || predefinedCity}
                      onChange={this.handleCityChange}
                      options={cities}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <button type="submit" className="btn-blue1">
                    Update Profile
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default updateLocation;
